/* eslint-disable max-lines */
import store from '@one-checkout/store'
import eventSlice from '@one-checkout/store/event.slice'
import {
  GA_DEFAULT_EVENT,
  getPlanSlugFromId,
  getProductType,
  getCategorySlugFromId,
  fixPrice,
  triggerEvent,
  triggerUniqueEvent,
} from '@one-checkout/services/event.service'

function buildEcommerceAttributes( event: eventSlice.State ) {
  return {
    'value': fixPrice( event.productPrice ),
    'transactionId': event.paymentHash,
    'shipping': event.shippingPrice,
    'discountCode': event.promotionCoupon,
    'items': [
      {
        'itemName': event.productName,
        'discount': event.totalDiscount,
        'category': getProductType( event.productType ),
        'category2': getCategorySlugFromId( event.categoryId ),
        'variant': getPlanSlugFromId( event.planId ),
        'quantity': 1,
        'price': fixPrice( event.productPrice ),
      }
    ]
  }
}


export function setEventData( event: eventSlice.State ) {
  store.dispatch( eventSlice.actions.setEventData( event ) )
}

export const beginCheckoutEvent = () => {
  const { event } = store.getState()
  triggerUniqueEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'begin_checkout',
    'eventCategory': getProductType( event.productType ),
    'eventLabel': 'CheckoutOnepage',
    'ecommerce': buildEcommerceAttributes( event ),
  } )
  triggerEvent( {
    'event': 'initiate_checkout',
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const selectItemEvent = () => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'select_item',
    'eventCategory': getProductType( event.productType ),
    'eventLabel': event.productName,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const beginShippingInfoEvent = () => {
  const { event } = store.getState()
  triggerUniqueEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'begin_shipping_info',
    'eventLabel': event.productName,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const addShippingInfoRequestEvent = () => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_shipping_info_request',
    'eventLabel': event.productName,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const addShippingInfoEvent = ( shippingResult: string ) => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_shipping_info',
    'eventLabel': shippingResult,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const addShippingInfoErrorEvent = ( error: string ) => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_shipping_info_error',
    'eventLabel': error,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const generateLeadEvent = ( status: 'request' | 'error' | 'success' ) => {
  const eventAction = 'generate_lead_' + status
  const { event } = store.getState()

  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventCategory': getProductType( event.productType ),
    eventAction,
    'ecommerce': buildEcommerceAttributes( event ),
  } )

  if ( status === 'success' ) {
    triggerEvent( {
      'event': 'generate_lead',
      'ecommerce': buildEcommerceAttributes( event ),
    } )
  }
}

export const giveMemberDiscountEvent = () => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'give_member_discount',
    'eventLabel': event.email,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const beginPaymentInfoEvent = () => {
  const { event } = store.getState()
  triggerUniqueEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'begin_payment_info',
    'eventCategory': getProductType( event.productType ),
    'eventLabel': event.productName,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const selectPaymentMethodEvent = () => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'select_payment_method',
    'eventLabel': event.paymentMethod,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const addPaymentInfoEvent = () => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_payment_info',
    'eventLabel': event.productName,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const pixPaymentAttemptEvent = () => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'pix_payment_attempt',
    'eventLabel': event.productName,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const purchaseSubmitEvent = () => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'purchase_submit',
    'eventLabel': event.productName,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const purchaseRequestEvent = () => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'purchase_request',
    'eventLabel': event.productName,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const purchaseErrorEvent = ( error: string ) => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'purchase_error',
    'eventLabel': error,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const addCouponSubmitEvent = () => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_coupon_submit',
    'eventLabel': event.promotionCoupon,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const addCouponRequestEvent = () => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_coupon_request',
    'eventLabel': event.promotionCoupon,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export const addCouponEvent = ( couponDiscount: number ) => {
  const { event } = store.getState()
  const ecommerceAttributes = buildEcommerceAttributes( event )
  ecommerceAttributes.items[ 0 ]!.discount = event.totalDiscount! + couponDiscount
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_coupon',
    'eventLabel': event.promotionCoupon,
    'ecommerce': ecommerceAttributes,
  } )
}

export const addCouponErrorEvent = ( error: string ) => {
  const { event } = store.getState()
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_coupon_error',
    'eventLabel': error,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}

export function productPurchaseEvent(): void {
  const { event } = store.getState()
  triggerEvent( {
    'event': 'product_purchase',
    'paymentMethod': event.paymentMethod,
    'ecommerce': buildEcommerceAttributes( event ),
  } )
}
