const GA_DEFAULT_EVENT = 'ga_product_event'

const getPlanSlugFromId = ( planId?: number ) => {
  if ( !planId ) {
    return null
  }
  const planSlug = ( {
    '1': 'Mensal',
    '2': 'Trimestral',
    '3': 'Anual',
    '4': 'Mensal',
    '5': 'Trimestral',
    '6': 'Anual',
  } as any )[ planId ]
  return planSlug || 'unregisteredPlan'
}

const getProductType = ( productSlug?: string ) => {
  if ( !productSlug ) {
    return null
  }
  const productType = ( {
    'club': 'Clubes',
    'oneTimeOffer': 'Clubes',
    'trail': 'Trilhas',
  } as any )[ productSlug ]
  return productType || 'unregisteredProduct'
}

const getCategorySlugFromId = ( categoryId?: number ) => {
  if ( !categoryId ) {
    return null
  }
  const categorySlug = ( {
    '1': 'Curadoria',
    '2': 'Ineditos',
  } as any )[ categoryId ]
  return categorySlug || 'unregisteredClub'
}

const fixPrice = ( price?: number ) => {
  if ( !price ) {
    return 0
  }
  return price.toFixed( 2 )
}

const triggerEvent = ( data: Record<string, any> ): void => {
  getGTMEventQueue().push( data )
}

const triggerUniqueEvent = ( data: Record<string, any> ): void => {
  const alreadyHadEvent = getGTMEventQueue().filter( event => event.eventAction === data[ 'eventAction' ] )
  if ( alreadyHadEvent && alreadyHadEvent.length ) {
    return
  }
  getGTMEventQueue().push( data )
}

const getGTMEventQueue = (): Array<any> => {
  if ( !window.dataLayer ) {
    window.dataLayer = []
  }

  return window.dataLayer
}

export {
  GA_DEFAULT_EVENT,
  getPlanSlugFromId,
  getProductType,
  getCategorySlugFromId,
  fixPrice,
  triggerEvent,
  triggerUniqueEvent,
  getGTMEventQueue,
}
