import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const eventSlice = createSlice( {
  'name': 'event',
  'initialState': {
    'paymentMethod': null as unknown as eventSlice.EventPaymentMethod,
    'productPrice': 0,
    'productType': '',
    'productName': '',
    'promotionCoupon': '',
    'planId': undefined,
    'categoryId': undefined,
    'paymentHash': '',
    'shippingPrice': null,
    'totalDiscount': 0,
    'email': '',
  } as eventSlice.State,
  'reducers': {
    setEventData( state, action: PayloadAction<eventSlice.State> ) {
      Object.assign( state, action.payload )
    },
  }
} )

namespace eventSlice {
  export interface State {
    paymentMethod?: 'pix' | 'card',
    productPrice?: number,
    productType?: string,
    productName?: string,
    promotionCoupon?: string,
    planId?: number,
    categoryId?: number,
    paymentHash?: string,
    shippingPrice?: number | null,
    totalDiscount?: number,
    email?: string,
  }

  export type EventPaymentMethod = 'pix' | 'card'
}

export default eventSlice
