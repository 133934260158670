/* eslint-disable react/no-danger */
import React from 'react'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ShippingLabel from '../IdentificationSummary/ShippingLabel'
import * as S from './styles'
import Product from '@shared/interfaces/Product'

function Conclusion( { product }: Readonly<Conclusion.Props> ) {
  return (
    <S.Container>
      <S.Image>
        <GatsbyImage
          image={ getImage( product.productImage )! }
          alt="Pedido realizado com sucesso"
        />
      </S.Image>
      <S.Description>
        <Typography
          color="CTA-400"
          variant="subtitle-large-bold"
        >
          <S.CustomTypography>
              Pedido realizado com sucesso!
          </S.CustomTypography>
        </Typography>

        { product.conclusion && (
          <Typography
            color="TAG1-700"
            variant="text-medium-regular"
          >
            <span dangerouslySetInnerHTML={{ '__html': product.conclusion }} />
          </Typography>
        ) }
      </S.Description>
      { product.hideShippingInfo ? null : <ShippingLabel /> }
    </S.Container>
  )
}
namespace Conclusion {
  export interface Props {
    product: Product
  }
}

export default Conclusion
