import { AddressService } from '@checkout/services'
import { formatCep, onlyNumbers } from '@checkout/utils/formatter'
import store from '@one-checkout/store'
import identificationSlice from '@one-checkout/store/identification.slice'
import shippingSlice from '@one-checkout/store/shipping.slice'
import { setEventData } from './event.action'
import { getAbsoluteDateFromWorkDays } from '@one-checkout/utils/date'

export async function getAddress( postalCode: string ): Promise<{ identificationData: identificationSlice.State, shippingData: shippingSlice.State }> {
  const unformattedPostalCode = onlyNumbers( postalCode )
  const { 'data': addressData } = await AddressService.get( unformattedPostalCode )

  const commomSuccessData = {
    'isValid': true,
    'helperText': '',
    'disabled': false,
  }

  const identificationData: any = {
    'postalCode': {
      ...commomSuccessData,
      'value': formatCep( addressData.zipCode ),
    },
    'city': {
      ...commomSuccessData,
      'value': addressData.city,
    },
    'state': {
      ...commomSuccessData,
      'value': addressData.state,
    },
    'street': {
      ...commomSuccessData,
      'value': addressData.street,
    },
    'district': {
      ...commomSuccessData,
      'value': addressData.district,
    },
    'ibge': addressData.ibgeCode
  }

  const planMonths = store.getState().product!.name.startsWith( 'yearly' ) ? 12 : 1
  const { isFreeShipping } = store.getState().product!
  const shippingFee = isFreeShipping ? 0.0 : addressData.shippingFee

  const shippingData: any = {
    'price': shippingFee * planMonths,
    'monthlyPrice': shippingFee,
    'deadline': getAbsoluteDateFromWorkDays( addressData.workDaysDelivery ).toString(),
  }

  setIdentificationData( identificationData )
  setShippingData( shippingData )
  setEventData( {
    'shippingPrice': shippingData.price,
    'email': identificationData.email ? identificationData.email.value : '',
  } )

  return {
    identificationData,
    shippingData,
  }
}

export function setIdentificationData( identificationData: any ) {
  store.dispatch( identificationSlice.actions.setIdentificationData( identificationData ) )
}

export function resetEmail() {
  store.dispatch( identificationSlice.actions.resetEmail() )
}

function setShippingData( shippingData: shippingSlice.SetShippingDataPayload ): void {
  store.dispatch( shippingSlice.actions.setShippingData( shippingData ) )
}
